<template>
  <div>
    <div class="top-title">
      <div class="title">项目信息-详情</div>
      <el-button plain v-if="$route.name ==='ContractList'" @click="handleBack">返回</el-button>
    </div>
    <!-- 项目概况 -->
    <el-tabs v-model="activeTab">
      <el-tab-pane v-for="item in projectList" :key="item.id" :name="item.id">
        <el-tooltip placement="top" slot="label" :content="item.projectName" :enterable="false">
          <div class="tab-pane-label">{{ item.parentId ? item.projectName: '项目主体' }}</div>
        </el-tooltip>

        <div>
          <unfold-and-stow name="项目信息">
            <ProjectForm :project-param="item" detail-mode />
          </unfold-and-stow>

          <unfold-and-stow name="相关方">
            <ProjectRelated detail-mode :related-list="item.projectRelatedResultList" />
          </unfold-and-stow>

          <unfold-and-stow name="附件">
            <ProjectFiles
              detail-mode
              :file-list="item.fileRelationResultList"
              :file-type-list="fileTypeList"
            />
          </unfold-and-stow>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import UnfoldAndStow from '@/views/main/basicPage/_components/unfoldAndStow';
import ProjectForm from '@/views/main/projectMana/projectForm.vue';
import ProjectRelated from '@/views/main/projectMana/projectRelated.vue';
import ProjectFiles from '@/views/main/projectMana/projectFiles.vue';

import { getBaseList, postEdit } from '@/api/common/common';

export default {
  components: { UnfoldAndStow, ProjectForm, ProjectRelated, ProjectFiles },
  data() {
    return {
      id: '',
      activeTab: '0',
      projectList: [],
      statusList: [],
      typeList: [],
      fileTypeList: []
    };
  },
  created() {
    this.id = sessionStorage.getItem('projectId');
    this.getProjectDetail();
    this.getFileTypeList(true);
  },
  methods: {
    // 获取文件类型
    async getFileTypeList(isEdit) {
      const res = await getBaseList({ type: 3 });
      this.fileTypeList = res.data;
      if (isEdit) {
        this.fileTypeList.unshift({ id: '0', name: '全部文件' });
      }
    },
    // 获取项目详情
    getProjectDetail() {
      postEdit({ id: this.id }).then(res => {
        res.data.forEach(item => {
          // 处理相关方数据
          item.projectRelatedResultList.forEach(subItem => {
            // 有立项部门为第一个建设单位
            if (subItem.establishmentDept) {
              subItem.code = '0';
            } else {
              subItem.code = subItem.id;
            }
          });
          // 处理文件数据
          item.fileRelationResultList = item.fileRelationResultList || [];
        });

        this.projectList = res.data;
        this.activeTab = res.data[0].id;
      });
    },
    // 点击返回
    handleBack() {
      this.$emit('back');
    }
  }
};
</script>

<style lang="less" scoped>
/deep/ .el-tabs__item {
  position: relative;
  font-size: 16px;
  min-width: 200px;
  max-width: 200px;
}

.top-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px 12px;
  font-size: 16px;
  font-weight: bold;
  border-bottom: 1px solid #e8e8e8;
  margin-bottom: 16px;
}

.tab-pane-label {
  text-align: center;
  max-width: 165px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
